import { notifyErrors } from '@/assets/js/utils'
import { t } from '@/plugins/i18n'
import store from '@/store'
import {
  destroyProduct, destroySku, fetchProduct, handleProductStatus, handleSkuStatus, replicateProduct
} from '@api/product'
import { ref } from '@vue/composition-api'

export default function useProductView() {
  const productData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadProduct = id => {
    productData.value = null
    loading.value = true

    return fetchProduct(id)
      .then(res => {
        productData.value = res.data.data

        return res
      })
      .catch(notifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  const trashProduct = id => destroyProduct(id).catch(notifyErrors)

  const cloneProduct = id => replicateProduct(id).catch(notifyErrors)

  const confirmTrashProduct = product =>

    // console.log('product is', product)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [{
        title: 'Confirm Action',
        content: `Confirm Trash product: <b>${t(product.title)}</b> ?`,
        resolve: () => trashProduct(product.id).then(resolve),
        resolveText: 'Confirm',
        reject: () => reject(),
        rejectText: 'No',
      }])
    })

  const confirmReplicate = product =>

    // console.log('product is', product)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [{
        title: 'Confirm Replicate',
        content: `Confirm Replicate product: <b>${t(product.title)}</b> ?`,
        resolve: () => cloneProduct(product.id).then(resolve),
        resolveText: 'Confirm',
        reject: () => reject(),
        rejectText: 'No',
      }])
    })

  const publishProduct = id => handleProductStatus(id, 'publish').catch(notifyErrors)

  const unpublishProduct = id => handleProductStatus(id, 'unpublish').catch(notifyErrors)

  const publishSku = id => handleSkuStatus(id, 'publish').catch(notifyErrors)

  const unpublishSku = id => handleSkuStatus(id, 'unpublish').catch(notifyErrors)

  const trashSku = id => destroySku(id).catch(notifyErrors)

  const confirmTrashSku = sku =>

    // console.log('sku is', sku)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [{
        title: 'Confirm Action',
        content: `Confirm Trash sku: <b>${t(sku.name)}</b> ?`,
        resolve: () => trashSku(sku.id).then(resolve),
        resolveText: 'Confirm',
        reject: () => reject(),
        rejectText: 'No',
      }])
    })

  return {
    loading,

    productData,

    loadProduct,
    trashProduct,
    confirmTrashProduct,
    confirmReplicate,
    publishProduct,
    unpublishProduct,
    publishSku,
    unpublishSku,
    confirmTrashSku,

  }
}
